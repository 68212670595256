
.why-choose-section{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 40px 0px;
    width: 100%;
    background-color: white;
}
.card-counter{
    position: absolute;
    right: 20px;
    top: 10px;
    font-family: 'Montserrat','Lato';
    font-size: 3.5rem;
    color: var(--mild-color);
    font-weight: bolder;
}
.why-choose-us-card-container{
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-top: 40px;
    flex-wrap: wrap;
}
.services-card{
    position: relative;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 3px 40px #e5e9f3;
    cursor: pointer;
    margin: 20px 0px;
}

.service-card-img {
    width: 100%;
}

.service-card-img .service-img {
    width: 100%;
    height: 150px;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    border-radius: 5px 5px 0px 0px;
}

.services-card .service-card-img img{
    height: 150px;
    width: 100%;
    object-fit: cover;
    background-position: center;
    background-size: 100% 100%;
    padding: 0;
    border-radius: 5px 5px 0px 0px;
}

.services-card .services-card-body {
    border-radius:0px 0px 5px 5px;
}

.services-card .services-card-body h2 {
    color: var(--dark);
    text-transform: capitalize;
    text-align: left;
    text-indent: 30px;
    font-weight: 600;
    margin-top: 10px;
}

.services-card .services-card-body p {
    color: var(--dark-mild);
    text-transform: capitalize;
    text-align: left;
    padding: 0px 30px 10px;
    font-weight: 500;
    font-size: .8em;
    margin-top: 10px;
    font-family: poppins;
}

.services-card .service-btn {
    margin-bottom: 25px;
    margin-top: 15px;
    width: 100%;
    padding-left: 50px;
}

.service-btn .launch-btn {
    margin-top: 0px;
}


   @media (max-width: 990px) and (min-width: 768px){
    .why-choose-us-text-container p{
        width: 90%;
    }
    .why-choose-us-card-container{
        flex-wrap: wrap;
    }
    .services-card{
        width: 45%;
        margin-top: 20px;
    }
   }
   @media screen and (max-width: 767px){
    .why-choose-us-text-container p{
        width: 100%;
    }
    .why-choose-us-card-container{
        flex-direction: column;
        width: 90%;
    }
    .services-card {
        width: 100%;
        margin: 20px 0px;
    }
    .why-choose-us-text-container h1{
        margin: 10px 0px;
        width: 100%;
    }
   } 