.homewrapper{
    width: 100%;
    background-color: #f2f2f2;
}
.dashboardhomepage{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px;
}
.dashboardhomepagewrapper{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.welcome-kyc-section{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
}
.welcome-kyc-section p{
    width: 100%;
    color: rgb(134, 146, 167);
    color: var(--color-variant);
}
.username-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: auto;
}
.username-container h2{
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: capitalize;
}
.username-container button{
    margin-left: 20px;
    padding: 10px 30px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(95, 95, 95);
    text-transform: capitalize;
    font-family: 'Lato';
    cursor: pointer;
    transition: .3s all ease-in-out;
}
.username-container button:hover{
    background-color: var(--primary-color);
    color: black;
}
.username-container button svg{
    font-size: 1.2rem;
    margin-left: 5px;
}
.kyc-card-wrapper{
    width: 80%;
    padding-left: 1px;
    background-color: rgb(239, 181, 37);
    border-radius: 5px;
    box-shadow: 0px 0px 5px #ddd;
    margin-top: 20px;
    margin-right: auto;
}
.kyc-card{
    width: 100%;
    padding: 10px;
    background-color: white;
    border-radius: 0px 10px 10px 0px;
    margin-left: 5px;
}
.kyc-card h3{
    font-size: 1.1rem;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Montserrat';
    color: rgb(195, 144, 16);
}
.kyc-card p{
    margin: 15px 0px 10px 0px;
    font-size: .9rem;
    color: rgb(113, 113, 113);
}
.kyc-card a{
    text-transform: capitalize;
    font-size: .8rem;
    color: rgb(177, 127, 0);
    font-weight: 500;
}
.kyc-line{
    width: 100px;
    margin-top: 3px;
    height: 2px;
    background-color: rgb(195, 144, 16);
}
.overview-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0px 50px 0px;
    flex-wrap: wrap;
}
.overview-card{
    width: 335px;
    background-color: white;
    box-shadow:0px 0px 1px rgba(147, 165, 230, 0.719);
    border-radius: 5px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    min-height: 230px;
    padding:10px 20px;
    position: relative;
}
.bar{
    position: absolute;
    bottom: 20px;
    right: 20px;
    object-fit: contain;
    height: 70px;
}
.amount-header{
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
}
.amount-header h2{
    font-size: .9rem;
    font-weight: 500;
    font-family: 'Lato';
    color: rgb(71, 125, 160);
}
.amount-holder{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin-right: auto;
    margin-top: 10px;
}
.amount-holder h3{
    font-size: 1.3rem;
    font-family: 'Lato';
    color: rgb(53, 53, 53);
    font-weight: 400;
}
.arrow-p{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.red{
    color: red;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.red p{
    margin-right: 2px;
    font-size: .8rem;
}
.red svg{
    font-size: 1.6rem;
    font-weight: 400;
}
.arrow-p p{
    margin-right: 2px;
    font-size: .8rem;
}
.arrow-p svg{
    font-size: 1.6rem;
    color: rgb(0, 158, 0);
    font-weight: 400;
}
.amount-pouches{
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}
.amount-pouches h2{
    font-size: .9rem;
    font-weight: 500;
    font-family: 'lato';
    color: rgb(71, 125, 160);
    margin-bottom: 5px;
    text-transform: capitalize !important;
}
.amount-pouches h3{
    font-size: 1rem;
    font-family: 'Lato';
    color: rgb(39, 39, 39);
    font-weight: 400;
    text-transform: capitalize;
}
.price-chartt-section{
    width: 100%;
    height: auto;
}
.referral-section{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0px;
}
.referral-card1{
    width: 45%;
    background-color: white;
    padding: 0px 20px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.referraltext-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.referraltext-wrapper button{
    padding: 10px 30px ;
    background-color: var(--secondary-color);
    text-transform: capitalize;
    color: white;
    font-size: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.referraltext-wrapper button:hover{
    background-color: rgb(12, 70, 185);
}
.referraltext-wrapper h2{
    font-size: 1rem;
    color: black;
    font-weight: 500;
    text-transform: capitalize;
    text-align: left;
}
.click-to-copy-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    border: 1px solid rgb(178, 192, 253);
    border-radius: 5px;
    padding: 10px;
    background-color: rgb(247, 250, 255);
}

.clipboard-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: white;
    box-shadow: 0px 0px 1px rgb(216, 216, 216);
    cursor: pointer;
}
.copied{
    color: green;
}
.click-to-copy-container input{
    font-size: .8rem;
    width: 90%;
    outline: none;
    background: rgb(247, 250, 255);
}
.referral-card1 p{
    width: 100%;
    text-align: left;
    color: rgb(72, 72, 72);
    font-size: .8rem;
}
.referral-card2{
    width: 43%;
    background-color: white;
    padding: 10px 20px;
}
.bar4{
    width: 100%;
    height: 70px;
    object-fit: contain;
}
.small-card{
    padding: 5px;
}
@media (max-width: 990px) and (min-width: 768px){
    .kyc-card-wrapper{
        width: 100%;
    }
    .overview-container{
        flex-wrap: wrap;
        margin-top: 0px;
    }
    .overview-card{
        margin-top: 30px;
        min-height: 200px;
    }
    .referral-card1{
        padding: 10px;
    }
    .dashboardhomepagewrapper{
        width: 90%;
    }
}
@media screen and (max-width: 767px){
    .kyc-card-wrapper{
        width: 100%;
    }
    .overview-container{
        flex-wrap: wrap;
        margin-top: 0px;
    }
    .overview-card{
        margin-top: 30px;
        min-height: 200px;
        width: 100%;
    }
    .referral-card1{
        padding: 10px;
        margin-bottom: 20px;
        width: 100%;
    }
    .referral-section{
        flex-direction: column;
    }
    .dashboardhomepagewrapper{
        width: 95%;
    }
}