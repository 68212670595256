*{
  padding: 0px;
  border: 0px;
  text-decoration: none;
  margin: 0px;
  box-sizing: border-box;
  list-style: none;
  font-family: Open Sans,'Lato','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
html{
  scroll-behavior: smooth;
  overflow-x: hidden;
  margin: 0px;
}
body{
  scroll-behavior: smooth;
  overflow-x: hidden;
  position: relative;
}
.home-price-chart{
  width: 90%;
  background-color: white;
  margin: auto;
  height: auto;
}
.home-price-chart-section{
  background-color: white;
  width: 100%;
  padding: 5% 5%;
  height: auto;
  margin: auto;
}
:root{
  --primary-color:rgb(227,246,245);
  --secondary-color:rgb(11, 174, 153);
  --facebook-mild:rgba(219, 235, 235, 0.546);
  --mild-color:#ddd;
  --dark-mild:#565674;
  --facebook:#2fa0ad;
  /* --facebook-variant:#044b7e; */
  /* --color-variant:rgb(11, 174, 153); */
  --facebook-variant:  rgb(11, 174, 153);
  --color-variant: #908CE1;
  --dark:rgb(31, 30, 88);
}

h1{
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  text-transform: capitalize;
  font-weight: 500;
}
h2{
  font-family: 'Poppins', 'roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.5rem;
}
h3{
  font-size: 1.5rem;
  font-weight: 500;
  font-family: 'Poppins','Montserrat','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-transform: capitalize;
  color: #0b4c89;
}
section{
  width: 85%;
  display: flex;
  padding: 10px;
  align-items: center;
}
p{
  font-size: 1rem;
  font-family: Open Sans,'roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 25px;
}
a{
  font-family: 'Poppins','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
body {
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: 'roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

button {
  font-family: 'roboto', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.header{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
}
.header h2{
  font-weight: 400;
  text-transform: uppercase;
  color: var(--facebook-variant);
  font-size: 1rem;
}
.header-line{
  width: 40px ;
  margin-right: 10px;
  height: 2px;
  background-color: var(--facebook-variant);
}
.why-choose-us-text-container h1{
  color: var(--dark);
  font-weight: 500;
  width: 70%;
  font-size: 2rem;
}
.why-choose-us-text-container p {
  color: var(--dark-mild);
  width: 50%;
}
.why-choose-us-text-container{
  width: 85%;
  text-align: left;
}
.launch-btn {
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-weight: 500;
  font-size: 17px;
  padding: 0.8em 2.3em 0.8em 1.9em;
  color: white;
  background: var(--color-variant);
  border: none;
  letter-spacing: 0.05em;
  border-radius: 10rem;
  margin-top: 40px;
  cursor: pointer;
}

.launch-btn svg {
  margin-right: 3px;
  transform: rotate(30deg);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.launch-btn span {
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.launch-btn:hover svg {
  transform: translateX(5px) rotate(90deg);
}

.launch-btn:hover span {
  transform: translateX(7px);
}
/* .signup-page{
  background-image: url(../public/bg11.jpg);
  background-color: #fff;
} */

.input-group {
  position: relative;
  margin: 20px 0px;
  width: 100%;
 }
 .input {
  border: 1.5px solid var(--color-variant);
  border-radius: 8px;
  background: none;
  padding: 1rem;
  font-size: 1rem;  
  color: var(--dark-mild);
  transition: border 150ms cubic-bezier(0.4,0,0.2,1);
  width: 100%;
  font-family: 'Lato', 'poppins';
 }
 
 .user-label {
  position: absolute;
  left: 15px;
  color: #adadad;
  color: var(--color-variant);
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4,0,0.2,1);
  font-family: 'Montserrat';
  font-size: .85rem;
  text-transform: capitalize;
 }
 
 .input:focus, .input:valid {
  outline: none;
  border: 1.5px solid var(--color-variant);
 }
 
 .input:focus ~ label, .input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  /* background-color: rgb(25, 60, 132); */
  backdrop-filter: blur(5px);
  padding: 0 .2em;
  color: var(--mild-color);
  color: var(--color-variant);
  text-transform: capitalize;
  font-size: 1rem;
 }
 .eye-container{
  position: absolute;
  padding: 5px;
  top:50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
 }
 .eye-container svg{
  color: var(--mild-color);
  pointer-events: none;
  font-size: 1.3rem;
  transition: .3s all ease-in-out;
 }
 .eye-container:hover svg{
  color: #1a73e8;
 }
 .already{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
 }
 .already p{
  color: var(--mild-color);
  text-transform: capitalize;
  font-size: .8rem;
  font-family: 'Lato';
  margin-right: 6px;

 }
 /* .sign-up-link{
  color: var(--color-variant);
  font-size: 1rem;
  font-family: 'Lato';
  text-transform: capitalize;
 }
 .sign-up-btn {
  --primary-color: #1a73e8;
  --secondary-color: #fff;
  --hover-color: rgba(0, 27, 132, 0.826);
  --arrow-width: 10px;
  --arrow-stroke: 2px;
  box-sizing: border-box;
  border: 0;
  border-radius: 20px;
  color: var(--secondary-color);
  padding: 1em 1.8em;
  background: var(--primary-color);
  display: flex;
  transition: 0.2s background;
  align-items: center;
  gap: 0.6em;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

.sign-up-btn .arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-btn .arrow {
  margin-top: 1px;
  width: var(--arrow-width);
  background: var(--primary-color);
  height: var(--arrow-stroke);
  position: relative;
  transition: 0.2s;
}

.sign-up-btn .arrow::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  border: solid var(--secondary-color);
  border-width: 0 var(--arrow-stroke) var(--arrow-stroke) 0;
  display: inline-block;
  top: -3px;
  right: 3px;
  transition: 0.2s;
  padding: 3px;
  transform: rotate(-45deg);
}

.sign-up-btn:hover {
  background-color: var(--hover-color);
}

.sign-up-btn:hover .arrow {
  background: var(--secondary-color);
}

.sign-up-btn:hover .arrow:before {
  right: 0;
} */
.userdashboard-page{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgb(240, 242, 246);
}

.o {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.c {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.4s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.e {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.6s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.a {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.8s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.n {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.v {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.oo {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.4s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.l {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.6s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.t {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.8s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.ee {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.d1 {
  color: black;
  opacity: 0;
  animation: pass1 2s ease-in-out infinite;
  animation-delay: 2.2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.d2 {
  color: black;
  opacity: 0;
  animation: pass1 2s ease-in-out infinite;
  animation-delay: 2.4s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

@keyframes pass {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pass1 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.wifi-loader-container{
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
}
.signUp-page 
.wifi-loader-container{
  position: fixed;
  top:0;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  margin-top: 0;
}

/* Hide the default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
 }
 
 .container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
 }
 
 /* Create a custom checkbox */
 .checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background: #606062;
  border-radius: 7px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.30), 0px 1px 1px rgba(0,5);
 }
 
 /* When the checkbox is checked, add a blue background */
 .container input:checked ~ .checkmark {
  background-image: linear-gradient(#255CD2,#1D52C1)
 }
 
 /* Create the checkmark/indicator (hidden when not checked) */
 .checkmark:after {
  content: "";
  position: absolute;
  display: none;
 }
 
 /* Show the checkmark when checked */
 .container input:checked ~ .checkmark:after {
  display: block;
 }
 
 /* Style the checkmark/indicator */
 .container .checkmark:after {
  left: 0.45em;
  top: 0.20em;
  width: 0.24em;
  height: 0.6em;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
 }
.rememberme-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
} 
.rememberme-container p{
  margin-left:20px ;
  font-size: .8rem;
  font-family: 'Lato';
  color: #ddd;
}
.success-page, .failure-page{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}
.success-img , .failure-img{
  width: 500px;
  height: 400px;
  object-fit: contain;
  margin-bottom: 20px;
}
.success-page a{
  padding: 10px 30px;
  background-color: rgb(20, 39, 135);
  color: white;
  text-transform: capitalize;
  margin-top: 10px;
  border-radius: 5px;
}
.failure-page a{
  padding: 10px 30px;
  background-color: rgb(20, 39, 135);
  color: white;
  text-transform: capitalize;
  margin-top: 10px;
  border-radius: 5px;
}
.failure-page p{
  text-transform: capitalize;
  color: rgb(20, 39, 135);
  font-size: .9rem;
}
.failure-page p{
  width: 60%;
}
.proof-container{
  width: 100%;
  padding: 10px;
}
.proof-form{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
}
.proof-img-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.proof-img-container svg{
  color: rgb(120, 120, 120);
  font-size: 8rem;
}
.proof-input{
  display: none;
}
.proof-label{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 50px;
  right: 50px;
  background-color: white;
  cursor: pointer;
  transition: .2s all ease-in-out;
}
.proof-label:hover{
  background-color: rgb(245, 245, 245);
}
.proof-submit-btn{
  margin-top: 25px;
  padding: 8px 40px;
  text-transform: capitalize;
  font-size: 1rem;
  color: white;
  background-color: rgb(25, 60, 132);
  cursor: pointer;
}
.checkout-info-container h3{
  font-size: 1rem;
  width: 80%;
  margin: 10px 0px 20px 0px;
  font-family: 'Lato';
  color: rgb(25, 60, 132);
}
.bold{
  font-size: 1rem;
  color: black;
  font-weight: bold;
}
.proof-img-container {
  width: 80%;
  height: 400px;
  background-color: rgb(248, 249, 253);
  margin: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.proof-image{
  min-width: 400px;
  min-height: 400px;
  object-fit: cover;
}
.floating-btn{
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(128, 128, 128);
  box-shadow: 0px 0px 5px rgb(188, 188, 188);
  top: 100px;
  left: 20px;
  font-size: 1.3rem;
  cursor: pointer;
}
.floating-btn:hover{
  box-shadow: 0px 0px 10px #ddd;
}

/* 
.signup-page{
  padding: 0px;
}
.sign-up-img img{
  height: 400px;
  object-fit: cover;
} */
.sub-btn{
  width: 100% !important;
  border-radius: 10px !important;
}
.home-chart{
  background-color: white;
}
.floating-btn svg, .clipboard-btn svg, .proof-img-container svg, .proof-label svg, .upload-icon svg{
  margin: auto;
}

.no-referral-page{
  width: 100%;
  padding: 0px 0px 50px 0px;
}
.no-referral-page a{
  width: 100%;
}
.failure-page p{
  font-size: 1.2rem;
  color: black;
  font-family: 'Montserrat','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
@media (max-width: 990px) and (min-width: 768px){

}
@media screen and (max-width: 767px){
  .home-price-chart-section{
    width: 100%;
  }
  .page-swiper-wrapper{
    min-height: 100vh;
    padding-bottom: 50px;
  }
  .rememberme-container p{
    text-align: left;
    font-size: .7rem;
  }
  .cont{
    padding: 0px;
    margin-right: auto;
    justify-content: flex-start;
    align-items: flex-start !important;
    width: 30px !important;
  }
  .checkbox {
    margin-right: auto;
    align-self: flex-start !important;
  }
  .rememberme-container{
    width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 0px;
    padding: 0px;
  }
  .rememberme-container p{
    margin-left: 0px;
  }
  .login-form-container{
    flex-direction: column;
    padding: 10px;
    width: 95%;
    padding-bottom: 100px;
  }
  .logintext-container img{
    margin-left: -20px;
  }
  .contact-form-container{
    width: 95%;
  }
  .contact-form{
    padding: 10px;
  }
  .checkout-info-container{
    max-width: 90% !important;
  }
  .checkout-info-container{
    max-width: 90% !important;
  }
  .withdrawal-review-container{
    flex-direction: column;
    width: 100%;
  }
  .modal{
    max-width: 95%;
  }
  .left-withdrawal-review-card{
    min-width: 100%;
  }
  .right-withdrawal-review-card{
    min-width: 100%;
    margin-top: 20px;
  }
  .review-left-card-tab{
    width: 100%;
  }
  .review-withdraw-form{
    width: 100%;
    justify-content: center;
    padding: 10px 0;
  }
  .review-withdraw-form input{
    width: 100% !important;
  }
  .confirm-withdraw-btn{
    width: 100%;
  }
  h1{
    font-size: 2rem;
  }
  .about-wrapper{
    flex-direction: column;
  }
  .logintext-container h1{
    width: 100%;
    font-size: 1.3rem;
  }
  .logintext-container{
    margin: 10px 0px 30px 0px;
    display: none;
  }
  .success-img , .failure-img{
    width: 100%;
  }
  .login-wrapper{
    padding: 0px !important;
  }
  .login-form-container{
    margin: 0px;
    width: 100%;
  }
  .login-form-container::after{
    clip-path: none;
  }
}