
.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 2%;
  box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 50px;
}

.signUp-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 2%;
  box-sizing: border-box;
  overflow-x: auto;
  padding: auto;
  margin-bottom: 50px;
}

.form_container {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 50px 40px 20px 40px;
    background-color: #ffffff;
    box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
      0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
      0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    font-family: "Inter", sans-serif;
  }
  
  .logo_container {
    box-sizing: border-box;
    width: 100px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo_container img {
    width: 150px;
  }
  
  .title_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .title {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--color-variant);
    text-transform: capitalize;
    font-family: poppins;
  }
  
  .subtitle {
    font-size: 0.725rem;
    max-width: 80%;
    text-align: center;
    line-height: 1.1rem;
    color: #8B8E98
  }
  
  .input_container {
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  
  .icon {
    width: 20px;
    position: absolute;
    z-index: 99;
    left: 12px;
    bottom: 9px;
    color: var(--dark-mild);
  }
  
  .input_label {
    font-size: 0.75rem;
    font-weight: 600;
    text-align: left;
    color: var(--color-variant);
  }
  
  .input_field {
    width: auto;
    height: 40px;
    padding: 0 0 0 40px;
    border-radius: 7px;
    outline: none;
    border: 1px solid #e5e5e5;
    filter: drop-shadow(0px 1px 0px #efefef)
      drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  }
  
  .input_field:focus {
    border: 1px solid transparent;
    box-shadow: 0px 0px 0px 2px var(--color-variant);
    background-color: transparent;
    /* border-color: var(--color-variant); */
  }


  .input_pass_container {
    padding: 0%;
    width: 100%;


  }

  .input_pass_container .input_field {
    width: 100%;
  }

  .input_pass_container .passIcon {
    position: absolute;
    right: 20px;
    color: var(--dark-mild);
  }
  
  .sign-in_btn {
    width: 100%;
    height: 40px;
    border: 0;
    background: var(--color-variant);
    border-radius: 7px;
    outline: none;
    color: #ffffff;
    cursor: pointer;
  }
  
  .separator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    color: #8B8E98;
  }
  
  .separator .line {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #e8e8e8;
  }
  
  .note {
    font-size: 0.8rem;
    color: #8B8E98;
    text-decoration: underline;
    cursor: pointer;
    font-family: poppins;
  }

  .remeberMex {
    display: flex;
    flex-direction: row;
  }

  .remeberMex label {
    text-transform: capitalize;
    color: var(--dark-mild);
    padding-left: 10px;
    font-size: .8em;
  }