 .plan-section{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    flex-direction: column;
    background-color: white;
    position: relative;
}
.plan-card-container{
  width: 88%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

  .plan {
    border-radius: 16px;
    padding: 10px;
    background-color: #fff;
    color: #697e91;
    width: 100%;
    max-width: 300px;
    box-shadow: 0px 0px 5px #cccccc;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  .plan strong {
    font-weight: 600;
    color: #425275;
  }
  
  .plan .inner {
    align-items: center;
    padding: 20px;
    padding-top: 40px;
    background-color: #ecf0ff;
    border-radius: 12px;
    position: relative;
  }
  
  .plan .pricing {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #bed6fb;
    border-radius: 99em 0 0 99em;
    display: flex;
    align-items: center;
    padding: 0.625em 0.75em;
    font-size: 1.25rem;
    font-weight: 600;
    color: #425475;
    color: var(--mild-color);
    background-color: var(--color-variant);
  }
  
  .plan .pricing small {
    color: #707a91;
    font-size: 0.75em;
    margin-left: 0.25em;
    color: var(--mild-color);
  }
  
  .plan .title {
    font-weight: 600;
    font-size: 1.25rem;
    color: #425675;
    text-transform: capitalize;
    text-align: left;
    color: var(--dark);
  }
  
  .plan .title + * {
    margin-top: 0.75rem;
  }
  
  .plan .info + * {
    margin-top: 1rem;
  }
  
  .plan .features {
    display: flex;
    flex-direction: column;
  }
  
  .plan .features li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .plan .features li + * {
    margin-top: 0.75rem;
  }
  
  .plan .features .iconxx {
    background-color: #1FCAC5;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  
  .plan .features .icon svg {
    width: 14px;
    height: 14px;
  }
  
  .plan .features + * {
    margin-top: 1.25rem;
  }
  
  .plan .action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .plan .button {
    background-color: #6558d3;
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    font-size: 1.125rem;
    text-align: center;
    border: 0;
    outline: 0;
    width: 100%;
    padding: 0.625em 0.75em;
    text-decoration: none;
  }
  
  .plan .button:hover, .plan .button:focus {
    background-color: #4133B7;
  }